<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <p class="project-header">ข้อคิดเห็นจากกรรมการสอบโครงงาน {{ project.topic_th }}<br></p>
        <!-- Exam button (Boron) -->
        <a v-if="is_examiner" :href="'https://boron.it.kmitl.ac.th/project-exam/score/' + this.$route.params.exam_type_id + '/' + project.uuid" target="_blank" class="sm:mr-16 xl:mr-0 exam-button">ให้คะแนนโครงงาน</a>
      </div>

      <!-- Project topic -->
      <div class="row mt-4">
        <p class="label">หัวข้อโครงงาน</p>
        <span class="project-content">{{ project.topic_th }}<br>({{ project.topic_en }})</span>
      </div>

      <!-- Program -->
      <div class="row mb-4">
        <p class="label">หลักสูตร</p>
        <span class="project-content">{{ program }}</span>
      </div>
      <hr>

      <!-- Student -->
      <p class="label mb-4">นักศึกษาผู้รับผิดชอบ</p>
      <div v-for="(member, index) in project.member_details" :key="member.id" class="row">
        <p class="font-normal name-space pl-4">{{ member.name }}</p>
        <p v-if="index === 0" class="label">รหัสนักศึกษา</p>
        <p v-else-if="index === 1" class="label"></p>
        <p class="font-normal">{{ member.code }}</p>
      </div>
      <hr>

      <!-- Advisor -->
      <div v-for="(advisor, index) in project.advisor_details" :key="advisor.id">
        <p v-if="(project.advisor_details.length === 1) && (index === 0)" class="row my-4">
          <span class="label">{{ project.course_details.type == 'CP' ? 'อาจารย์ที่ปรึกษา (นิเทศ)' : 'อาจารย์ที่ปรึกษา' }}</span>
          <span class="font-normal advisor-name-space">{{ project.advisor_details[0].name }}</span>
          <span class="sm:-ml-8 xl:ml-0 label">อาจารย์ที่ปรึกษาร่วม</span>
          <span class="font-normal">-</span>
        </p>
        <p v-if="(project.advisor_details.length === 2) && (index === 0)" class="row my-4">
          <span class="label">{{ project.course_details.type == 'CP' ? 'อาจารย์ที่ปรึกษา (นิเทศ)' : 'อาจารย์ที่ปรึกษา' }}</span>
          <span class="font-normal advisor-name-space">{{ project.advisor_details[0].name }}</span>
          <span class="sm:-ml-8 xl:ml-0 label">อาจารย์ที่ปรึกษาร่วม</span>
          <span class="font-normal">{{ project.advisor_details[1].name }}</span>
        </p>
      </div>
      <hr>

      <!-- Project document -->
      <div class="row">
        <p class="label mb-4">ไฟล์และเอกสารของโครงงาน</p>

        <!-- Old document -->
        <div v-if="(project.old_project != null && course.year <= 2564)" class="font-normal">
          <div v-for="document in project.old_project_details" :key="document" class="my-auto"> 
            <a v-if="document != null" :href="document[0] != 'วิดีโอนำเสนอ' ? 'https://carbon.it.kmitl.ac.th/pdf/old_document/' + document[1] : document[1]" target="_blank">
              <button class="view-button mb-2" style="background-color: #FFC700;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
            </a>
            <span v-if="document != null" class="ml-4">{{ document[0] }}</span>
          </div>
        </div>

        <!-- New document -->
        <div v-else class="font-normal">
          <div v-for="document in documents" :key="document.id" class="my-auto"> 
            <a :href="document.path" target="_blank">
              <button class="view-button mb-2" style="background-color: #FFC700;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
            </a>
            <span class="ml-4">{{ document.title }}</span>
          </div>
        </div>
      </div>

      <!-- Exam score (Advisor) -->
      <p v-show="is_advisor" class="mt-8 project-header">สรุปคะแนนการประเมิน</p>
      <table v-show="is_advisor" v-if="type == '01'">
        <thead>
          <tr>
            <th class="w-1/12">รหัสนักศึกษา</th>
            <th class="w-1/12">ชื่อ-นามสกุล</th>
            <th class="w-1/12">คะแนน (ที่ปรึกษา)</th>
            <th class="w-1/12">คะแนน (กรรมการสอบ)</th>
            <th class="w-1/12">รวม</th>
          </tr>
        </thead>
        <tbody>
          <tr><td colspan="5"><hr></td></tr>
          <tr v-for="student in exam_score.students" :key="student.id">
            <td>{{ student.student_id }}</td>
            <td>{{ student.name }}</td>
            <td v-if="student.total_score.length >= 2 && student.total_score[0].type == '01'">{{ student.total_score[0].total_score }}</td>
            <td v-else-if="student.total_score.length >= 2 && student.total_score[1].type == '01'">{{ student.total_score[1].total_score }}</td>
            <td v-else>-</td>
            <td v-if="student.total_score.length >= 2 && student.total_score[0].type == '02'">{{ student.total_score[0].total_score }}</td>
            <td v-else-if="student.total_score.length >= 2 && student.total_score[1].type == '02'">{{ student.total_score[1].total_score }}</td>
            <td v-else>-</td>
            <td v-if="student.total_score.length >= 2">{{ student.total_score[0].total_score +  student.total_score[1].total_score }}</td>
            <td v-else>-</td>
          </tr>
        </tbody>
      </table>

      <!-- Poster score -->
      <table v-show="is_advisor" v-if="type == '02'">
        <thead>
          <tr>
            <th class="w-1/12">รหัสนักศึกษา</th>
            <th class="w-1/12">ชื่อ-นามสกุล</th>
            <th class="w-1/12">คะแนน (กรรมการสอบ)</th>
            <th class="w-1/12">รวม</th>
          </tr>
        </thead>
        <tbody>
          <tr><td colspan="4"><hr></td></tr>
          <tr v-for="student in exam_score.students" :key="student.id">
            <td>{{ student.student_id }}</td>
            <td>{{ student.name }}</td>
            <td v-if="student.total_score.length != 0 && student.total_score[0].type == '02'">{{ student.total_score[0].total_score }}</td>
            <td v-else>-</td>
            <td v-if="student.total_score.length != 0">{{ student.total_score[0].total_score }}</td>
            <td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { changeProgramName } from "../mixins/other"

export default {
  name: "ExamReview",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      project: {},
      course: {},
      documents: [],
      exams: [],
      exam_score: [],
      program: "",
      role: null,
      is_advisor: false,
      is_examiner: false,
    }
  },
  async created() {
    document.title = "ข้อคิดเห็นกรรมการสอบ | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "TCH") {
      this.$router.push("/")
    }
    this.project = await this.$store.dispatch("getProjectById", this.$route.params.project_id)
    this.course = await this.$store.dispatch("getCourseById", this.$route.params.course_id)
    this.type = this.$route.params.type
    let doc = await this.$store.dispatch("getFilteredProjectDocument", { course: this.$route.params.course_id, project: this.$route.params.project_id, status: "P" })
    this.documents = doc.documents
    if (this.project.advisors.includes(this.$store.getters.payload.user_id)) {
      this.is_advisor = true
    }
    this.program = changeProgramName(this.project.course_details.program_code)
    this.exam_score = await this.$store.dispatch("getExamScore", { course: this.course.id, semester: this.course.semester, year: parseInt(this.course.year), uuid: this.project.uuid, exam_type: this.type })
    await this.checkIsExaminer()
    this.loading = false
  },
  methods: {
    async checkIsExaminer() {
      this.exams = await this.$store.dispatch("getExam", { course: this.course.id, semester: this.course.semester, year: parseInt(this.course.year), role: this.role, email: this.$store.getters.payload.email, exam_type: this.type, uuid: this.project.uuid })
      this.exams = this.exams[0]
      let email = this.$store.getters.payload.email
      if (this.exams.examiner_list.includes(email)) {
        this.is_examiner = true
      }

      // This user isn't examiner
      if (!this.is_examiner) {
        this.$router.push("/exam")
      }
    },
}}
</script>